import useSWR, { KeyedMutator } from 'swr';
import { IUserRoleDto } from '../models/Einstellungen/userRoleDto';

interface IUsersRolesGet {
  userRolesData: Array<IUserRoleDto> | undefined;
  error: any;
  isValidating: boolean;
  mutate: KeyedMutator<Array<IUserRoleDto>>;
}

export function useUsersRolesGet(): IUsersRolesGet {
  const {
    data: userRolesData,
    error,
    isValidating,
    mutate
  } = useSWR<Array<IUserRoleDto>>(`/api/v1/config/usersRoles`, {
    suspense: false
  });

  return { userRolesData, error, isValidating, mutate };
}
