import classes from '../../../Schreibtisch.module.css';
import { ReactElement, useState } from 'react';
import { FjdCollapsibleCard, FjdStack } from 'fjd-react-components';
import {
  hasNestedProperty,
  hasProperty,
  isObject,
  isTypedProperty
} from '../../../../../utils/objectUtil';
import {
  CaseDetailsModel,
  TEidPersonalData,
  TMetadatenWithEid
} from '../../../../../models/Case/CaseDetailsModel';
import { FormatUtil } from '../../../../../utils/formatUtil';

interface IShowAusweisdaten {
  givenNames: string;
  givenFamilyNames: string;
  street: string;
  zipCity: string;
  country: string;
  birthDate: string;
  nationality?: string;
  birthName?: string;
}

// check if we have the eid data object
function hasEidPersonalDataPath(obj: unknown): obj is TMetadatenWithEid {
  return hasNestedProperty(obj, [
    'additionalReferenceInfo',
    'x-applicant',
    'eidPersonalData'
  ]);
}

// check if the eid data object looks like it should
function isEidPersonalDataValid(obj: unknown): obj is TEidPersonalData {
  if (!isObject(obj)) {
    return false;
  }

  return (
    isTypedProperty<TEidPersonalData>(obj, 'givenNames', 'string') &&
    isTypedProperty<TEidPersonalData>(obj, 'familyNames', 'string') &&
    isObject(obj.dateOfBirth) &&
    isTypedProperty<TEidPersonalData['dateOfBirth']>(
      obj.dateOfBirth as Record<string, unknown>,
      'dateValue',
      'string'
    ) &&
    isObject(obj.placeOfResidence) &&
    isObject(
      (obj.placeOfResidence as Record<string, unknown>).structuredPlace
    ) &&
    isTypedProperty<TEidPersonalData['placeOfResidence']['structuredPlace']>(
      (obj.placeOfResidence as Record<string, unknown>)
        .structuredPlace as Record<string, unknown>,
      'street',
      'string'
    ) &&
    isTypedProperty<TEidPersonalData['placeOfResidence']['structuredPlace']>(
      (obj.placeOfResidence as Record<string, unknown>)
        .structuredPlace as Record<string, unknown>,
      'city',
      'string'
    ) &&
    isTypedProperty<TEidPersonalData['placeOfResidence']['structuredPlace']>(
      (obj.placeOfResidence as Record<string, unknown>)
        .structuredPlace as Record<string, unknown>,
      'zipCode',
      'string'
    ) &&
    isTypedProperty<TEidPersonalData['placeOfResidence']['structuredPlace']>(
      (obj.placeOfResidence as Record<string, unknown>)
        .structuredPlace as Record<string, unknown>,
      'country',
      'string'
    ) &&
    (!hasProperty<TEidPersonalData>(obj, 'nationality') ||
      typeof obj.nationality === 'string') &&
    (!hasProperty<TEidPersonalData>(obj, 'birthName') ||
      typeof obj.birthName === 'string')
  );
}

// Get the object like we need it in the component
function validateAndMapToAusweisdaten(
  caseDetailsModel: CaseDetailsModel
): IShowAusweisdaten | undefined {
  if (!hasEidPersonalDataPath(caseDetailsModel.initialSubmission.metadaten)) {
    return undefined;
  }

  const eidPersonalData: TEidPersonalData =
    caseDetailsModel.initialSubmission.metadaten.additionalReferenceInfo[
      'x-applicant'
    ].eidPersonalData;

  if (!isEidPersonalDataValid(eidPersonalData)) {
    return undefined;
  }

  return {
    givenNames: eidPersonalData.givenNames,
    givenFamilyNames: eidPersonalData.familyNames,
    street: eidPersonalData.placeOfResidence.structuredPlace.street,
    zipCity: `${eidPersonalData.placeOfResidence.structuredPlace.zipCode} ${eidPersonalData.placeOfResidence.structuredPlace.city}`,
    country: eidPersonalData.placeOfResidence.structuredPlace.country,
    birthDate: eidPersonalData.dateOfBirth.dateValue
      ? FormatUtil.formatDateTime(
          new Date(eidPersonalData.dateOfBirth.dateValue),
          'DD.MM.YYYY'
        )
      : eidPersonalData.dateOfBirth.dateValue,
    nationality: eidPersonalData.nationality,
    birthName: eidPersonalData.birthName
  };
}

interface AusweisdatenProps {
  readonly caseDetailsModel: CaseDetailsModel;
}

function Ausweisdaten(props: AusweisdatenProps): ReactElement {
  const [openCard, setOpenCard] = useState(false);
  const ausweisdaten: IShowAusweisdaten | undefined =
    validateAndMapToAusweisdaten(props.caseDetailsModel);

  return (
    <FjdStack orientation="vertical">
      <FjdCollapsibleCard
        iconExpanded="chevron-up"
        iconCollapsed="chevron-down"
        headingColor="background-alternate"
        onToggle={(expanded) => setOpenCard(expanded)}
        expanded={openCard}
        heading={
          <h2 style={{ marginTop: '0.17rem' }}>
            <span
              style={{ marginLeft: '0.63rem' }}
              data-testid={'expand_collapse_ausweidaten'}
            >
              Ausweisdaten
            </span>
            {!ausweisdaten && (
              <span
                style={{
                  marginLeft: '0.4rem',
                  fontWeight: 'normal'
                }}
              >
                (liegen nicht vor)
              </span>
            )}
          </h2>
        }
      >
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <div
            data-testid={'ausweisdaten'}
            className={classes.collapsibleCardBody}
          >
            <dt data-testid={'names_label'}>Vorname(n)</dt>
            <dd data-testid={'names_value'} className={classes.data}>
              {ausweisdaten ? ausweisdaten.givenNames : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'familynames_label'}>Nachname(n)</dt>
            <dd data-testid={'familynames_value'} className={classes.data}>
              {ausweisdaten
                ? ausweisdaten.givenFamilyNames
                : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'strasse_label'}>Straße, Haus-Nr.</dt>
            <dd data-testid={'strasse_value'} className={classes.data}>
              {ausweisdaten ? ausweisdaten.street : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'plz_ort_label'}>PLZ, Ort</dt>
            <dd data-testid={'plz_ort_value'} className={classes.data}>
              {ausweisdaten ? ausweisdaten.zipCity : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'staat_label'}>Staat</dt>
            <dd data-testid={'staat_value'} className={classes.data}>
              {ausweisdaten ? ausweisdaten.country : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'geburtsdatum_label'}>Geburtsdatum</dt>
            <dd data-testid={'geburtsdatum_value'} className={classes.data}>
              {ausweisdaten ? ausweisdaten.birthDate : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'nationalitaet_label'}>Nationalität</dt>
            <dd data-testid={'nationalitaet_value'} className={classes.data}>
              {ausweisdaten && ausweisdaten.nationality
                ? ausweisdaten.nationality
                : '- Keine Angabe -'}
            </dd>
            <dt data-testid={'geburtsname_label'}>Geburtsname</dt>
            <dd data-testid={'geburtsname_value'} className={classes.data}>
              {ausweisdaten && ausweisdaten.birthName
                ? ausweisdaten.birthName
                : '- Keine Angabe -'}
            </dd>
          </div>
        </div>
      </FjdCollapsibleCard>
    </FjdStack>
  );
}

export default Ausweisdaten;
