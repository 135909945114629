import {
  Badge,
  Icon,
  IconButton,
  Table,
  Title
} from '@efast_public/fjd-component-library';
import { FjdSpinner } from 'fjd-react-components';
import React, {
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { SortOrder } from '@efast_public/fjd-component-library/build/common/types/sorting';
import { useUsersRolesGet } from '../../../../hooks/useUsersRoles';
import {
  IGroupDto,
  IRoleDto,
  IUserRoleDto
} from '../../../../models/Einstellungen/userRoleDto';
import {
  SortChangeParameter,
  sortUserRolesTable
} from './usersAndRolesManagementTableSort';
import {
  isUserAdministrator,
  isUserRegistered,
  removeRoleSachbearbeitung
} from './utils/usersAndRoles';

function getStatusBadge(status: boolean) {
  switch (status) {
    case true:
      return (
        <Badge size="sm" iconBefore="checkmark-filled" variant="success">
          registriert
        </Badge>
      );
    default:
      return (
        <Badge size="sm" variant="inProgress">
          eingeladen
        </Badge>
      );
  }
}

interface TableColumn {
  label: string;
  value: string;
  isSticky?: boolean;
  sortable?: boolean;
}

const tableColumns: Array<TableColumn> = [
  { label: 'Nachname', value: 'lastName', sortable: true },
  { label: 'Vorname', value: 'firstName', sortable: true },
  { label: 'E-Mail Adresse', value: 'email' },
  { label: 'Gruppe', value: 'group ' },
  { label: 'Rolle', value: 'role' },
  { label: 'Admin', value: 'isAdmin' },
  { label: 'Status', value: 'status' },
  { label: '', value: 'menu', isSticky: true }
];

export interface UserRolesTable extends IUserRoleDto {}

interface UserAndRoleManagementTableProps {
  readonly width: string;
}

export default function UsersAndRolesManagementTable(
  props: UserAndRoleManagementTableProps
): React.ReactElement {
  const [currentSortedColumn, setCurrentSortedColumn] =
    useState<SortChangeParameter>();
  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);
  const [userRolesDataState, setUserRolesDataState] = useState<
    Array<IUserRoleDto> | undefined
  >();

  const { userRolesData, isValidating } = useUsersRolesGet();

  useEffect(() => {
    setUserRolesDataState(userRolesData);
  }, [userRolesData]);

  const handleHideChange = (
    hiddenColumnsProp: SetStateAction<Array<number>>
  ): void => setHiddenColumns(hiddenColumnsProp);

  const handleSortChange: (sortedColumn: SortChangeParameter) => void =
    useCallback(
      (sortedColumn: SortChangeParameter) => {
        setCurrentSortedColumn(sortedColumn);
      },
      [setCurrentSortedColumn]
    );

  const sortedUserRoleManagementTable = useMemo(():
    | Array<IUserRoleDto>
    | undefined => {
    return sortUserRolesTable(userRolesDataState, currentSortedColumn);
  }, [currentSortedColumn, userRolesDataState]);

  return (
    <div style={{ width: props.width }}>
      {isValidating && (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <FjdSpinner size="m" />
        </div>
      )}
      <Table
        title={
          <Title level={2} visualLevel={6}>
            Benutzer:innen und Rollen verwalten
          </Title>
        }
        ascendingLabel="Aufsteigend"
        descendingLabel="Absteigend"
        onHideChange={handleHideChange}
        hiddenColumns={hiddenColumns}
        hideColumnLabel="Spalte ausblenden"
        onSortChange={(column: {
          id: string;
          sortOrder: SortOrder;
          value: string;
          index: number;
        }) =>
          handleSortChange({
            ...column,
            value: column.value as keyof UserRolesTable
          })
        }
      >
        <Table.Columns sticky>
          {tableColumns.map(
            (column: TableColumn): ReactElement => (
              <Table.Column
                isHideable
                sortable={column.sortable}
                key={column.value}
                sticky={column.isSticky}
                value={column.value}
                data-testid={`testId-col-${column.value}`}
              >
                {column.label}
              </Table.Column>
            )
          )}
        </Table.Columns>
        <Table.Rows>
          <>
            {sortedUserRoleManagementTable?.map(
              (row: UserRolesTable, index: number): ReactNode => {
                return (
                  <Table.Row key={row.id}>
                    <Table.Cell data-testid={`testId-cell-${row.lastName}`}>
                      {row.lastName}
                    </Table.Cell>
                    <Table.Cell data-testid={`testId-cell-${row.firstName}`}>
                      {row.firstName}
                    </Table.Cell>
                    <Table.Cell>{row.email}</Table.Cell>
                    <Table.Cell data-testid={`testId-column-group-${index}`}>
                      {row.groups.length === 1 && isUserAdministrator(row) ? (
                        '–'
                      ) : (
                        <ul>
                          {row.groups.map(
                            (groupItem: IGroupDto): false | ReactNode =>
                              groupItem.name !== 'Administration' && (
                                <li key={groupItem.name}>{groupItem.name}</li>
                              )
                          )}
                        </ul>
                      )}
                    </Table.Cell>
                    <Table.Cell data-testid={`testId-column-role-${index}`}>
                      {row.groups.length === 1 && isUserAdministrator(row) ? (
                        '–'
                      ) : (
                        <ul>
                          {row.groups.map(
                            (
                              groupItem: IGroupDto
                            ): ReactNode | Array<ReactNode> => {
                              if (groupItem.roles.length === 2) {
                                const filteredRoles: Array<IRoleDto> =
                                  removeRoleSachbearbeitung(groupItem);
                                return (
                                  <li
                                    key={groupItem.id}
                                    data-testid={`testId-byGroup-${groupItem.name}-role-${filteredRoles.map((role: IRoleDto): string => role.name)}`}
                                  >
                                    {filteredRoles.map(
                                      (role: IRoleDto): string => role.name
                                    )}
                                  </li>
                                );
                              }
                              return groupItem.roles.map(
                                (role: IRoleDto): ReactNode => (
                                  <li
                                    key={role.id}
                                    data-testid={`testId-byGroup-${groupItem.name}-role-${role.name}`}
                                  >
                                    {role.name}
                                  </li>
                                )
                              );
                            }
                          )}
                        </ul>
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'center' }}>
                      {isUserAdministrator(row) && (
                        <Icon
                          data-testid={`testId-icon-checkmark-filled-for-user-${row.lastName}`}
                          variant="success"
                          name="checkmark-filled"
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell
                      data-testid={`testId-status-${isUserRegistered(row) ? 'registered' : 'invited'}-for-user-${row.lastName}`}
                    >
                      {getStatusBadge(isUserRegistered(row))}
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        icon="overflow-menu-vertical"
                        variant="tertiary"
                        label="menu"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )}
          </>
        </Table.Rows>
      </Table>
    </div>
  );
}
