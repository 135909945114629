export function isObject(obj: unknown): obj is Record<string, unknown> {
  return obj !== null && typeof obj === 'object';
}

export function hasProperty<T>(
  obj: Record<string, unknown>,
  prop: keyof T
): boolean {
  return prop in obj;
}

export function isTypedProperty<T>(
  obj: Record<string, unknown>,
  prop: keyof T,
  type: string
): boolean {
  return hasProperty<T>(obj, prop) && typeof (obj as T)[prop] === type;
}

export function hasNestedProperty(obj: unknown, path: Array<string>): boolean {
  let current: unknown = obj;
  for (const key of path) {
    if (!isObject(current) || !(key in current)) {
      return false;
    }
    current = current[key];
  }
  return true;
}
