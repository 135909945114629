import {
  IGroupDto,
  IRoleDto,
  IUserRoleDto
} from '../../../../../models/Einstellungen/userRoleDto';

export const isUserAdministrator: (row: IUserRoleDto) => boolean = function (
  row: IUserRoleDto
): boolean {
  return row.groups.some((group: IGroupDto) => group.name === 'Administration');
};

export const isUserRegistered: (row: IUserRoleDto) => boolean = function (
  row: IUserRoleDto
): boolean {
  return (row.firstName && row.lastName) !== null;
};

export function removeRoleSachbearbeitung(
  groupItem: IGroupDto
): Array<IRoleDto> {
  return groupItem.roles.filter(
    (role: IRoleDto): boolean => role.name !== 'Sachbearbeitung'
  );
}
