import { FjdButton, FjdSpinner, FjdStack } from 'fjd-react-components';
import React, { useContext, useState } from 'react';
import classes from '../../Schreibtisch.module.css';
import {
  caseDetailsMapper,
  extractAttachmentRequestData,
  FachdatenNode
} from '../../../../models/Case/CaseDetailsModel';
import Metadaten from './Metadaten/Metadaten';
import AttachmentPreviewModal from '../../../shared/AttachmentPreviewModal';
import VermerkData from './Vermerk/VermerkData';
import FachdatenNodeComponent from './Fachdaten/FachdatenNode';
import { caseDetailsDataContext } from '../../../../context/Context';
import { initialCaseDetailsData } from '../../../../context/CaseDetailsDataContext/CaseDetailsDataContext';
import { Spacer, Switch } from '@efast_public/fjd-component-library';
import Ausweisdaten from './Ausweisdaten/Ausweisdaten';

const Antragsdetails = () => {
  const caseDetails = useContext(caseDetailsDataContext);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [hideEmptyApplicationData, setHideEmptyApplicationData] =
    useState(false);

  const submissionData =
    caseDetails && caseDetails?.data?.caseId !== ''
      ? caseDetailsMapper(caseDetails?.data)
      : null;

  const fachdaten = submissionData?.fachdaten ?? new FachdatenNode(undefined);

  const attachmentRequestData = extractAttachmentRequestData(
    caseDetails?.data?.initialSubmission
  );

  if (!caseDetails || !caseDetails?.data?.initialSubmission) {
    return <p> Es sind keine Daten vorhanden.</p>;
  }

  if (hideEmptyApplicationData) {
    processIterationAndFiltering(fachdaten);
  }

  function processIterationAndFiltering(fachdaten: FachdatenNode): void {
    if (fachdaten.children.length === 0) {
      return;
    }
    fachdaten.children.forEach((child): void => {
      filterChildrenByValue(child);
      processIterationAndFiltering(child);
    });

    filterChildrenByValue(fachdaten);
  }

  function filterChildrenByValue(fachdaten: FachdatenNode): void {
    fachdaten.children = fachdaten.children.filter((currentNode) => {
      currentNode.children = currentNode.children.filter((child) => {
        return child.labelValue?.value !== '- Keine Angabe -';
      });

      return (
        currentNode.children.length > 0 ||
        currentNode.labelValue?.label !== 'label'
      );
    });
  }

  const fachdatenView: React.ReactNode = submissionData ? (
    <FachdatenNodeComponent node={fachdaten} depthLevel={0} />
  ) : (
    <p> Es sind keine Daten vorhanden.</p>
  );

  return (
    <>
      <AttachmentPreviewModal
        onClose={() => setOpenAttachmentModal(false)}
        attachmentId={attachmentRequestData?.attachmentId}
        submissionId={attachmentRequestData?.submissionId}
        caseId={caseDetails?.data?.caseId}
        isOpen={openAttachmentModal && attachmentRequestData !== null}
      />
      {Object.entries(caseDetails.data).toString() !==
      Object.entries(initialCaseDetailsData).toString() ? (
        <div className={classes.horizontal}>
          <div className={classes.leftbox}>
            <div>
              <h2 style={{ marginBottom: '10px' }}>Antragsdaten</h2>
              <br></br>
              <div className={classes.formularansicht}>
                <Switch
                  label="Leere Antragsdaten ausblenden"
                  className={classes.switch}
                  onChange={(checked) => setHideEmptyApplicationData(!checked)}
                />
                <Spacer />
                {attachmentRequestData && (
                  <FjdButton
                    appearance={'primary-link'}
                    label={'Formularansicht'}
                    iconLeft={'view'}
                    key={Math.random()}
                    onClick={() => setOpenAttachmentModal(true)}
                    id={'formularansicht_display'}
                  />
                )}
                {!attachmentRequestData && (
                  <div className={classes.noFormularAnsicht}></div>
                )}
              </div>
            </div>
            {fachdatenView}
          </div>
          <div className={classes.rightbox}>
            <div className={classes.sticky}>
              <div className={classes.scroll}>
                <FjdStack orientation="vertical">
                  <VermerkData
                    caseDetails={caseDetails.data}
                    onStateChanged={() => caseDetails.refresh()}
                  />
                  <Metadaten />
                  <Ausweisdaten caseDetailsModel={caseDetails.data} />
                </FjdStack>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FjdSpinner size="s" />
      )}
    </>
  );
};

export default Antragsdetails;
