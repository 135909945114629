import { Bearbeitungsstand } from '../Status/Bearbeitungsstand';
import { Bescheidstatus } from '../Status/Bescheidstatus';
import { VermerkDto } from '../Vermerk/VermerkDto';
import { BearbeiterDto } from '../Antrag/AntragsModel';

export class CaseDetailsModel {
  caseId: string;
  name: string;
  auftragsnummer: string;
  abgeschlossenAm: Date | string | null;
  initialSubmission: SubmissionDetailsDto;
  bescheidstatus: Bescheidstatus;
  bearbeitungsstand: Bearbeitungsstand;
  createdOn?: Date | string;
  vermerkDto?: Array<VermerkDto> | null;
  hasError?: boolean;
  canCommunicate: boolean;
  isBeschieden: boolean;
  isPostalisch: boolean;
  organizationalUnitName: string;
  version: string;
  bearbeiterDto?: BearbeiterDto | null;
  errorCode?: string;
  constructor(
    caseId: string,
    name: string,
    auftragsnummer: string,
    abgeschlossenAm: Date | string | null,
    initialSubmission: SubmissionDetailsDto,
    bescheidstatus: Bescheidstatus,
    bearbeitungsstand: Bearbeitungsstand,
    canCommunicate: boolean,
    isBeschieden: boolean,
    isPostalisch: boolean,
    organizationalUnitName: string,
    version: string,
    createdOn?: Date | string,
    vermerkDto?: Array<VermerkDto> | null,
    hasError?: boolean,
    errorCode?: string,
    firstInteractionTimestamp?: Date | string,
    bearbeiterDto?: BearbeiterDto
  ) {
    this.caseId = caseId;
    this.name = name;
    this.auftragsnummer = auftragsnummer;
    this.abgeschlossenAm = abgeschlossenAm;
    this.initialSubmission = initialSubmission;
    this.bescheidstatus = bescheidstatus;
    this.bearbeitungsstand = bearbeitungsstand;
    this.createdOn = createdOn;
    this.vermerkDto = vermerkDto;
    this.hasError = hasError;
    this.canCommunicate = canCommunicate;
    this.isBeschieden = isBeschieden;
    this.isPostalisch = isPostalisch;
    this.errorCode = errorCode;
    this.organizationalUnitName = organizationalUnitName;
    this.version = version;
    this.firstInteractionTimestamp = firstInteractionTimestamp;
    this.bearbeiterDto = bearbeiterDto;
  }

  firstInteractionTimestamp?: Date | string | null;
}

export interface SubmissionDetailsDto {
  submissionId: string;
  fachdaten: string | object;
  metadaten: string | object;
  JsonNode?: string;
  attachmentDtoList: Array<AttachmentModel>;
  absenderName: string | null;
  isVonBehoerde: boolean;
  isBescheid: boolean;
  createdOn: string;
}

export type TMetadatenWithEid = {
  additionalReferenceInfo: {
    'x-applicant': {
      eidPersonalData: TEidPersonalData;
    };
  };
};

export type TEidPersonalData = {
  givenNames: string;
  familyNames: string;
  dateOfBirth: { dateValue: string };
  placeOfResidence: {
    structuredPlace: {
      street: string;
      city: string;
      zipCode: string;
      country: string;
    };
  };
  nationality?: string;
  birthName?: string;
};

export interface AttachmentModel {
  id: string;
  payload: string | null;
  pdfPreviewPayload?: string | null;
  purpose: string;
  filetype: string;
  description: string;
  mimeType: string;
  size: string | number;
  createdOn?: string | Date;
  isBescheid: boolean;
}

export interface AttachmentExtendedModel extends AttachmentModel {
  absenderName: string;
  isVonBehoerde: boolean;
  createdOn: Date;
  submissionId: string;
}

export class CaseDetails {
  metaData: Array<Displaydatamodel>;
  fachdaten: FachdatenNode;
  vermerk: Array<VermerkDto> | undefined;

  constructor(
    metaData: Array<Displaydatamodel>,
    fachdaten: FachdatenNode,
    vermerk: Array<VermerkDto> | undefined
  ) {
    this.metaData = metaData;
    this.fachdaten = fachdaten;
    this.vermerk = vermerk;
  }
}

export class Displaydatamodel {
  title: string;
  labelValue: Array<LabelValue>;

  constructor(title: string, labelValue: Array<LabelValue>) {
    this.title = title;
    this.labelValue = labelValue;
  }
}

export class LabelValue {
  label: string;
  value: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

export interface AttachmentRequest {
  attachmentId: string;
  submissionId: string;
}

export class CaseCountModel {
  count: number;

  constructor(count: number) {
    this.count = count;
  }
}

export class FachdatenNode {
  children: Array<FachdatenNode> = [];
  labelValue: LabelValue | undefined;
  rootNode: boolean;

  constructor(value: LabelValue | undefined) {
    this.labelValue = value;
    this.rootNode = false;
  }

  asRootNode() {
    this.rootNode = true;
    return this;
  }

  isGroup() {
    return this.children.length > 0;
  }

  isRootNode() {
    return this.rootNode;
  }

  setValue(value: LabelValue) {
    this.labelValue = value;
  }

  addChild(child: FachdatenNode) {
    this.children.push(child);
  }

  getLabel() {
    if (this.labelValue) {
      return this.labelValue.value;
    }

    return '';
  }
}

export const caseDetailsMapper = (
  caseDetails: CaseDetailsModel | undefined
): CaseDetails | null => {
  if (caseDetails) {
    let fachdaten: FachdatenNode = toFachdatenNode(
      caseDetails.initialSubmission
    );

    let metadatenList: Array<Displaydatamodel> = toMetadatenList(
      caseDetails.initialSubmission
    );
    return new CaseDetails(
      metadatenList,
      fachdaten,
      caseDetails.vermerkDto ? caseDetails.vermerkDto : undefined
    );
  }
  return null;
};

function toFachdatenNode(submissionDetailsList: SubmissionDetailsDto) {
  let node: FachdatenNode = new FachdatenNode(undefined).asRootNode();

  if (!submissionDetailsList) {
    return node;
  }

  let submissionDetails = JSON.parse(
    JSON.stringify(submissionDetailsList.fachdaten)
  );
  Object.entries(submissionDetails).forEach(([key, value]) => {
    node.addChild(createNode(key, value));
  });

  return node;
}

function createNode(key: string, value: unknown) {
  let node = new FachdatenNode(undefined);

  if (key.startsWith('F')) {
    let fieldValue: string =
      // @ts-ignore
      value['value'] === null || value['value'] === ' '
        ? '- Keine Angabe -'
        : // @ts-ignore
          value['value'];

    // @ts-ignore
    let fieldLabel: string = value['label'];
    return new FachdatenNode(new LabelValue(fieldLabel, fieldValue));
  } else if (key.startsWith('G')) {
    let nextPairList = JSON.parse(JSON.stringify(value));
    Object.entries(nextPairList).forEach(([key2, value2]) => {
      if (!(key2 === 'label')) {
        node.addChild(createNode(key2, value2));
      } else {
        let label: string = typeof value2 === 'string' ? value2 : '';
        node.setValue(new LabelValue(key2, label));
      }
    });
  }

  return node;
}

function createMetadatenFachdaten(key: string, value: unknown) {
  let labelValues: Array<LabelValue> = new Array<LabelValue>();
  // @ts-ignore
  Object.entries(value).forEach(([key2, value2]) => {
    labelValues.push(new LabelValue(key2, value2 as string));
  });
  return new Displaydatamodel(key, labelValues);
}

function toMetadatenList(submissionDetailsList: SubmissionDetailsDto) {
  let metadatenList: Array<Displaydatamodel> = new Array<Displaydatamodel>();

  if (!submissionDetailsList) {
    return metadatenList;
  }

  let submissionDetails = JSON.parse(
    JSON.stringify(submissionDetailsList.metadaten)
  );
  Object.entries(submissionDetails).forEach(([key, value]) => {
    metadatenList.push(createMetadatenFachdaten(key, value));
  });

  return metadatenList;
}

export const extractAttachmentRequestData = (
  submissionDetails: SubmissionDetailsDto
): AttachmentRequest | null => {
  let attachment;
  let submissionId;
  if (submissionDetails && submissionDetails?.attachmentDtoList) {
    attachment = submissionDetails?.attachmentDtoList.find(
      (att: AttachmentModel) => att?.purpose === 'form'
    );
    submissionId = submissionDetails?.submissionId;
    if (attachment && submissionId) {
      return { attachmentId: attachment.id, submissionId: submissionId };
    }
  }

  return null;
};
